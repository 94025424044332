import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'

import liffapi from '../liffapi.js'

export const useOrgStore = defineStore('organization', {
  state: () => ({
    categories: useLocalStorage('categories', {}),
    notifications: [],
    org: useLocalStorage('organization', {}),
  }),

  getters: {
    hasAds(state) {
      return state.org?.displayOptions?.hasAds
    },

    hasInfo(state) {
      return Boolean(state.org?.mapUrl) || Boolean(this.hasValidOperatingHours)
    },

    hasLiffAlerts(state) {
      return Boolean(state.org?.displayOptions?.hasLiffAlerts)
    },

    hasLiffProtect(state) {
      return (
        Boolean(state.org?.displayOptions?.hasLiffProtect) &&
        Boolean(state.org?.protectOpts?.wallpaperUrl)
      )
    },

    hasValidOperatingHours(state) {
      return state.org?.operation_hours && state.org?.operation_hours.length > 0
    },

    isSocialSharable(state) {
      let socials = state.org?.social || {}

      return Object.keys(socials).some((k) => state.org?.social[k])
    },

    mapUrl(state) {
      return state.org?.mapUrl
    },

    orgUrl(state) {
      return state.org?.orgUrl
    },

    protectWallpaperUrl(state) {
      return (
        state.org?.protectOpts?.wallpaperUrl ||
        state.org?.displayOptions?.protectWallpaperUrl
      )
    },

    protectOpts(state) {
      return state.org?.protectOpts
    },

    slug(state) {
      return state.org?.slug
    },

    twitterHandle(state) {
      return state.org?.social.orgTwitterHandle
    },
  },

  actions: {
    loadCategories() {
      this.notifications = this.notifications || []

      return liffapi.categories
        .get()
        .then((result) => {
          this.categories = Object.assign({}, result?.data)
          let note = {
            message: `Categories ${Object.keys(result?.data)?.length}`,
            body: result,
            type: 'notification',
            read: false,
          }
          this.notifications?.push(note)
        })
        .catch((error) => {
          throw new Error(`Load categories failed: ${error}`)
        })
    },

    loadOrg() {
      this.notifications = this.notifications || []

      return liffapi.organization
        .get()
        .then((result) => {
          Object.assign(this.org, result.data)
          let note = {
            message: `Organization ${result?.data?.slug}`,
            body: result,
            type: 'notification',
            read: false,
          }
          this.notifications?.push(note)
        })
        .catch((error) => {
          throw new Error(`Load org failed: ${error}`)
        })
    },
  },
})
